import { useCameraInCurrentScene } from "@/modes/alignment-modes-commons/align-to-cad-utils";
import { PointCloudObject } from "@/object-cache";
import { EntityPinControls } from "@/registration-tools/common/interaction/entity-pin-controls";
import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import {
  centerCameraOnPointClouds,
  computeCombinedPointCloudCenter,
} from "@/registration-tools/utils/camera-views";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  ExplorationControls,
  useNonExhaustiveEffect,
  useReproportionCamera,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { GUID, TypedEvent } from "@faro-lotv/foundation";
import {
  Map2DControls as Map2DControlsImpl,
  WalkOrbitControls,
} from "@faro-lotv/lotv";
import { DataSetLocalPose } from "@faro-lotv/service-wires";
import { useCallback, useRef, useState } from "react";
import { Matrix4, OrthographicCamera, PerspectiveCamera, Vector3 } from "three";
import { useCameraAnimation } from "../hooks/use-camera-animation";
import { selectSelectedEntityId } from "../store/data-preparation-ui/data-preparation-ui-selectors";
import {
  selectRevisionEntity,
  selectRevisionEntityWorldTransformCache,
} from "../store/revision-selectors";
import { computeLocalEntityPoseFromWorldTransforms } from "../store/revision-transform-cache";
import { Projection } from "../ui/projection-switch";
import {
  frameOrthoCameraFromPerspectiveView,
  framePerspectiveCameraFromOrthoView,
} from "../utils/ortho-perspective-switch-utils";

/** Position threshold at which an animation is played */
const ANIMATION_THRESHOLD = 0.1;

type RevisionScansControlsProps = {
  /** An event which is triggered when the camera should be centered. */
  centerCameraEvent: TypedEvent<Perspective>;

  /** The point cloud objects in the scene. */
  pointCloudObjects: PointCloudObject[];

  /** The active projection method */
  projection: Projection;

  /** Whether registration editing is currently enabled */
  isEditRegistrationEnabled: boolean;

  /** Callback when the user adds a new transform override for a point cloud. */
  onManualOverrideAdded(id: GUID, pose: DataSetLocalPose): void;
};

/**
 * @returns Controls to inspect the scans and manage the camera.
 */
export function RevisionScansControls({
  projection,
  pointCloudObjects,
  centerCameraEvent,
  isEditRegistrationEnabled,
  onManualOverrideAdded,
}: RevisionScansControlsProps): JSX.Element {
  const [activeProjection, setActiveProjection] = useState(
    Projection.orthographic,
  );

  const { getState } = useAppStore();

  const [orthoCamera] = useState(new OrthographicCamera());
  const [perspCamera] = useState(new PerspectiveCamera());

  const explorationControlsRef = useRef<WalkOrbitControls>(null);
  const map2dControlsRef = useRef<Map2DControlsImpl>(null);

  const [lastTarget, setLastTarget] = useState(() =>
    computeCombinedPointCloudCenter(pointCloudObjects),
  );

  const { animationToRender, startAnimation, animationCamera } =
    useCameraAnimation();

  const activeProjectionCamera = orthoCamera;
  // activeProjection === Projection.orthographic ? orthoCamera : perspCamera;

  const activeCamera = animationToRender
    ? animationCamera
    : activeProjectionCamera;

  useCameraInCurrentScene(activeCamera);
  useReproportionCamera(activeCamera);

  // Trigger the transition from one camera to another, only when the projection changes
  useNonExhaustiveEffect(() => {
    if (projection !== activeProjection) {
      if (projection === Projection.perspective) {
        setLastTarget(
          framePerspectiveCameraFromOrthoView(
            perspCamera,
            orthoCamera,
            lastTarget,
          ),
        );
      } else {
        const referencePoint = explorationControlsRef.current?.target;

        // Skip logic if controls have been in walk mode. Use the last camera state as a fallback.
        if (referencePoint) {
          frameOrthoCameraFromPerspectiveView(
            orthoCamera,
            perspCamera,
            referencePoint,
          );

          // The animation "rotates" the perspective camera around the pivot to match the orthographic camera's direction
          startAnimation(perspCamera, {
            position: referencePoint
              .clone()
              .add(
                new Vector3(
                  0,
                  0,
                  perspCamera.position.distanceTo(referencePoint),
                ).applyQuaternion(orthoCamera.quaternion),
              ),
            quaternion: orthoCamera.quaternion,
            duration: 0.5,
          });

          setLastTarget(referencePoint);
        }
      }

      setActiveProjection(projection);
    }
  }, [projection, activeProjection]);

  const centerCamera = useCallback(
    (perspective: Perspective) => {
      if (activeProjectionCamera instanceof OrthographicCamera) {
        centerCameraOnPointClouds(
          pointCloudObjects,
          activeProjectionCamera,
          perspective,
        );

        if (map2dControlsRef.current) {
          // Whenever the view type (perspective) changes,
          // the camera is re-assigned to the controls so its
          // pose is not changed by the controls.
          map2dControlsRef.current.camera = orthoCamera;
        }
      }

      setLastTarget(computeCombinedPointCloudCenter(pointCloudObjects));
    },
    [activeProjectionCamera, pointCloudObjects, orthoCamera],
  );
  useTypedEvent<Perspective>(centerCameraEvent, centerCamera);

  const selectedEntityId = useAppSelector(selectSelectedEntityId);
  const selectedEntity = useAppSelector(selectRevisionEntity(selectedEntityId));

  const onSelectedEntityMoved = useCallback(
    (worldMatrix: Matrix4) => {
      if (selectedEntityId && selectedEntity) {
        const parentTransform =
          // A root element needs to be indicated by undefined to computeLocalEntityPoseFromWorldTransforms, not a default transform
          selectedEntity.parentId === null
            ? undefined
            : new Matrix4().fromArray(
                selectRevisionEntityWorldTransformCache(
                  selectedEntity.parentId,
                )(getState()).worldMatrix,
              );

        onManualOverrideAdded(
          selectedEntityId,
          computeLocalEntityPoseFromWorldTransforms(
            worldMatrix,
            parentTransform,
          ),
        );
      }
    },
    [selectedEntityId, selectedEntity, getState, onManualOverrideAdded],
  );

  if (animationToRender) {
    return animationToRender;
  }

  switch (activeProjection) {
    case Projection.orthographic:
      return (
        <EntityPinControls
          manipulatedEntityId={
            isEditRegistrationEnabled ? selectedEntityId : undefined
          }
          onTransform={onSelectedEntityMoved}
          mapControlsRef={map2dControlsRef}
          camera={orthoCamera}
        />
      );
    case Projection.perspective:
      return (
        <ExplorationControls
          ref={explorationControlsRef}
          camera={orthoCamera}
          target={lastTarget}
        />
      );
  }
}
